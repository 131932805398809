.row {
  /* border: blue 1px solid; */
  margin: 5px;
  /* border: red solid 1px; */
  /* margin-top: 15px; */
  /* padding: 10px; */
}

.col {
  /* border: orange 1px solid; */
  margin: 5px;
  /* border: blue solid 1px; */
  /* margin-top: 0px;
  padding-right: 5px;
  padding-left: 5px; */
  /* padding: 10px; */
}
/* 
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
} */

/* .alert {
  font-weight: bold;
} */

/* .page-header {
  background-color: rgb(243, 243, 243);
} */
